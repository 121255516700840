import { createI18n } from 'vue-i18n'
import en from './Locales/en.json'
import sv from './Locales/sv.json'

function loadLocaleMessages() {
    const locales = [{ en: en }, { sv: sv }]
    const messages = {}
    locales.forEach(lang => {
        const key = Object.keys(lang)
        messages[key] = lang[key]
    })
    return messages
}

export default createI18n({
    legacy: false,
    locale: 'sv',
    fallbackLocale: 'en',
    messages: loadLocaleMessages(),
})
